.is-background-div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

@each $tpe in diag {
  .has-#{$tpe} {
    @each $name, $pair in $colors {
      &.has-#{$tpe}-#{$name} {
        &::after {
          background: url("/assets/img/#{$name}-#{$tpe}.svg");
          background-size: 100% 100%;
        }
      }
    }
    @extend .is-background-div;
  }
  @each $dir in top, bottom, right, left {
    @each $rev in '', -rev {
      @each $name, $pair in $colors {
        &.has-#{$tpe}-#{$dir}#{$rev}-#{$name} {
          &::before {
            @extend .is-background-div;
            background: url("/assets/img/#{$name}-#{$tpe}#{$rev}.svg");
            background-size: 100% 100%;
            content: "";
          }
          &.has-no-zindex::before {
            z-index: auto;
          }
          @each $i in 0, 90, 180, 270 {
            &.rotate-#{$i}::before {
              transform: rotate(#{$i}deg) !important;
            }
          }
        }
      }
      .has-#{$tpe}-#{$dir}#{$rev} {
          z-index: -1;
          position: absolute;
          @if $dir != bottom {
            top: -1px;
          }
          @if $dir != top {
            bottom: -1px;
          }
          @if $dir != left {
            right: 0;
          }
          @if $dir != right {
            left: 0;
          }
      }
      @for $i from 1 to 30 {
        &.has-#{$tpe}-#{$dir}#{$rev}-#{$i} {
          @if $dir == top or $dir == bottom {
            height: #{$i / 2}em;
            @include from($desktop) {
              height: #{$i}em;
            }
          } @else {
            width: #{$i / 2}em;
            @include from($desktop) {
              width: #{$i}em;
            }
          }
        }
      }
    }
  }
}

#scai-on-aws {
  .is-background-image {
    background-image: url("/assets/img/scaidata_aws_marketplace_free_trial_business_intelligence_analytics.jpg");
  }
}

#scai-on-azure {
  .is-background-image {
    background-image: url("/assets/img/scaidata_azure_marketplace_free_trial_business_intelligence_analytics.jpg");
  }
}

#scai-on-google {
  .is-background-image {
    background-image: url("/assets/img/scaidata_google_gcp_marketplace_business_intelligence_analytics_free_trial.jpg");
  }
}

#scai-more-than-business-intelligence {
  .is-background-image {
    background-image: url("/assets/img/scaidata_business_intelligence_analytics_free_trial_cloud.jpg");
  }
}
#scai-platform-free-trial {
  .is-background-image {
    background-image: url("/assets/img/scaidata_free_trial_business_intelligence_analytics_automation_web_platform.jpeg");
  }
}

.is-background-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

#scai-applications {
  .is-background-image {
    background-image: url("/assets/img/scaidata_business_intelligence_data_analytics_sql_databases_workflow_automation.jpg");
  }
}

#scai-marketing-applications {
  .is-background-image {
    background-image: url("/assets/img/scaidata_free_trial_business_intelligence_analytics_automation_web_platform.jpeg");
  }
}

#scai-engineering-applications {
  .is-background-image {
    background-image: url("/assets/img/scaidata_solutions_business_intelligence_automation_analytics_engineering.jpg");
  }
}


#scai-sales-applications {
  .is-background-image {
    background-image: url("/assets/img/scaidata_analytics_tool_for_sales_reporting_targets_web_platform_self_service_free_trial.jpg");
  }
}

#scai-web-and-digital-analytics-applications {
  .is-background-image {
    background-image: url("/assets/img/scaidata_free_trial_web_digital_analytics_web_platform_applications_free_trial.jpg");
  }
}

#scai-gaming-applications {
  .is-background-image {
    background-image: url("/assets/img/scaidata_solutions_business_intelligence_automation_analytics_gaming.jpg");
  }
}

#scai-finance-applications {
  .is-background-image {
    background-image: url("/assets/img/scaidata_business_intelligence_automation_cloud_analytics.jpg");
  }
}

#scai-on-premise {
  .is-background-image {
    background-image: url("/assets/img/scaidata_scai_platform_on_premise_pricing.jpg");
  }
}

#scai-platform-business-intelligence-data-management {
  .is-background-image {
    background-image: url("/assets/img/scaidata_scaiplatform_dashboard_business_intelligence_reporting.jpeg");
  }
}

#scaiplatform-business-intelligence {
  .is-background-image {
    background-image: url("/assets/img/scaidata_scaiplatform_dashboard_business_intelligence_data_management.jpeg");
  }
}
