@import '_variables.scss';
@import '_mixins.scss';
@import '_floating_label.scss';
@import '_team.scss';
@import '_vertical_layout.scss';
@import '_faq.scss';

// Finally, bulma
@import 'lib/bulma/bulma.sass';

@import '_background.scss';


html, body {
  font-family: 'Open Sans';
}

.columns.is-vertical {
  flex-direction: column;
}

.vertical-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.vertical-items-mobile {
  @include mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

h1, h2, h3, h4 {
  font-family: 'Open Sans', Serif;
}

.card {
  @extend .img-transition;
  &:hover {
    @extend .img-transition:hover;
    box-shadow: 0px 2px 20px rgba($logoBlue, 0.8);
  }
  .card-content {
    z-index: 1;
  }
}

#scai-platform-business-intelligence-automation .card {
  border-color: $logoBlue;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  p.title {
    font-size: 1.5rem;
  }
  @include mobile {
    & {
      max-height: 500px;
      .card-image {
        overflow: hidden;
      }
    }
  }
}

#scai-platform-business-intelligence-automation {
  .expander {
    margin-left: 0.5em;
  }
  .subtitle {
    margin-bottom: 0 !important;
  }
}

img.is-vertical {
  max-height: 100%;
  height: 100%;
  width: auto;
  min-height: 0;
  object-fit: contain;
}

@function scai-margin-container($container-size, $extra-gap) {
  @return calc((100vw - #{$container-size} + 2 * #{$gap}) / 2 + #{$extra-gap});
}

@each $dir in left, right {
  @each $point in $desktop, $widescreen, $fullhd {
    @include from($point) {
      .padding-#{$dir}-container, .padding-#{$dir}-container-desktop {
        padding-#{$dir}: scai-margin-container($point, 0px);
      }
    }
  }
  @include touch {
    .padding-#{$dir}-container {
      padding-#{$dir}: $gap;
    }
  }
}

.feature-section {
  .column .hero {
    height: 100%;
  }
  :not(.flex-row-reverse) {
    .hero-body {
      padding-left: 0 !important;
      padding-right: $gap !important;
    }
  }
  .flex-row-reverse {
    .hero-body {
      padding-left: $gap !important;
      padding-right: 0 !important;
    }
  }
  img {
    display: block;
  }
}

.has-shadow-logoBlue {
  box-shadow: 10px 10px 7px $logoBlue;
}

.is-paddingless-horizontal {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

@each $i in row, column {
  .flex-#{$i}-reverse {
    flex-direction: #{$i}-reverse;
  }
}

.bd-notification {
  min-height: 140px;
  border: 1px solid $light;
  border-radius: 4px;
  margin: 40px;
  text-align: center;
  box-shadow: 0px 0px 8px $light;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: $default_transition;
  &:hover {
    transform: scale(1.1);
    @each $name, $pair in $colors {
      $color: nth($pair, 1);
      $color-invert: nth($pair, 2);
      &.is-#{$name} {
        color: $color;
        box-shadow: 0px 2px 20px rgba($color, 0.9);
      }
    }
    cursor: pointer;
  }
}

header {
 background: linear-gradient(-45deg, $primary 0%, $info 100%);
 background-size: cover;
 &.is-full-screen {
   @include until(2000px) {
     min-height: 50vh;
   }
   @include from(2000px) {
     min-height: 50vh;
   }
 }
 section#scaiintro {
   > div.container > div.columns {
     min-height: 40vh;
   }
 }
}

footer {
  background: $scaiBackground;
}

video {
  width: 100%;
}

div.scaiheading {
  h1.title, h2.title {
    margin-bottom: 1rem !important;
  }
  h2.subtitle, h3.subtitle {
    @include desktop {
      margin-bottom: 4rem !important;
    }
    @include touch {
      margin-bottom: 2rem !important;
    }
  }
}

div.divider {
  position: relative;
  &.is-small {
    height: 1px;
    width: 100%;
  }
  &:not(.is-small) {
    height: 4px;
    width: 60px;
  }
  display: block;
  text-align: center;
  margin-top: 13px;
  margin-bottom: 13px;
  &.has-text-centered {
    margin: 13px auto;
  }
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    &.is-#{$name} {
      background: $color;
    }
  }
  border-radius: 5em;
}

div.card-content > div.content {
  @extend .has-text-centered;
}

.form {
  .buttons {
    padding-top: 10px;
  }
}

article.tile {
  .title {
    font-size: 1.5rem;
    padding-bottom: 0.5rem;
  }
  .subtitle {
    font-size: 1rem;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.social {
  display: block;
  margin: 30px;
}

@mixin social-round($width) {
  padding: 15px $width;
  margin: 0px 8px;
  border: 2px solid $white;
  border-radius: 100%;
}

.social-facebook {
  @include social-round(20.5px);
}

.social-linkedin {
  @include social-round(17px);
}

@each $name, $pair in $colors {
  $color: nth($pair, 1);
  $color-invert: nth($pair, 2);
  .is-text-#{$name} {
    color: $color;
  }
  .is-#{$name}-hover {
    &:hover, &:active {
      color: $color;
    }
  }
  .is-#{$name}-background-hover {
    &:hover, &:active {
      background: $color-invert;
    }
  }
}

.navbar-burger {
  &:not(.is-active) {
    > :not(:first-child) {
      display: none;
    }
  }
  &.is-active {
    > :first-child {
      display: none;
    }
  }
}

@include touch {
  .navbar-end {
    border-top: 0.1px solid $white;
    border-bottom: 0.1px solid $white;
  }
  .navbar-brand > * {
    background: transparent !important;
  }
}

@include desktop {
  .navbar-burger {
    display: none;
  }
}

.with-border-bottom {
  border-bottom: 1px solid $light;
}

.img-transition {
  transition: 0.7s;
  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
}

.navbar-dropdown {
  .navbar-item {
    color: $white;
  }
}

.has-dropdown {
  @include touch {
    &:not(.is-active) {
      > .navbar-dropdown {
        display: none;
      }
    }
    .navbar-link {
      > .arrow:after {
        @include arrow($primary-invert);
      }
    }
    &:not(.is-active) {
      .navbar-link > .arrow:after {
        top: 40%;
      }
    }
    &.is-active {
      .navbar-link > .arrow:after {
        border: 1px solid $white !important;
        border-bottom: 0 !important;
        border-left: 0 !important;
        top: 50%;
      }
    }
  }
}

.gradientbtn {
  background: linear-gradient(-45deg, darken($primary, 20%), $primary, $info);
  animation: Gradient 5s ease infinite;
  color: $white !important;
  background-size: 300% 300% !important;
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

.scai-main-image {
  height: 80%;
  width: 80%;
}

@for $i from -30 to 30 {
  @each $tpe in margin, padding {
    .has-#{$tpe}-#{$i} {
      #{$tpe}: #{$i}rem !important;
    }
    @include desktop {
      .has-#{$tpe}-#{$i}-desktop {
        #{$tpe}: #{$i}rem !important;
      }
    }
    @include mobile {
      .has-#{$tpe}-#{$i}-mobile {
        #{$tpe}: #{$i}rem !important;
      }
    }
    @each $dir in top, right, bottom, left {
      .has-#{$tpe}-#{$dir}-#{$i} {
        #{$tpe}-#{$dir}: #{$i}rem !important;
      }
      @include desktop {
        .has-#{$tpe}-#{$dir}-#{$i}-desktop {
          #{$tpe}-#{$dir}: #{$i}rem !important;
        }
      }
      @include mobile {
        .has-#{$tpe}-#{$dir}-#{$i}-mobile {
          #{$tpe}-#{$dir}: #{$i}rem !important;
        }
      }
    }
  }
}

.img-shadow {
  box-shadow: 6px 9px 9px $light, 0 0 0 1px $light;
}

.no-underline {
  text-decoration: none !important;
  outline: none;
}

.display-block {
  display: block;
}

.industry, .department {
    display: none;
}

.industries:hover {
  div.industry {
    display: unset;
  }
}

img.is-medium  {
  width: 15rem;
}

.card.pricing-card {
    display: flex;
    flex-direction: column;
    .card-content {
      flex: auto;
    }

    .button {
      margin-bottom: 1rem;
    }

    @include from($desktop) {
      height: 600px;
      p {
        line-height: 1.5;
      }
    }
    @include until($desktop) {
      min-height: 350px;
    }
    max-width: 400px;
    @include from($widescreen) {
      min-width: 400px;
    }
}

.highlighted {
   border: 3px solid $green;
}

@include until($tablet){
  #scaiintro {
    h1, h2 {
      text-align: center;
    }
    div#scai_intro_buttons {
      justify-content: center;
    }
  }
}

img.img-medium {
  max-height: 45%;
  max-width: 45%;
  @include until($tablet) {
    max-height: 90%;
    max-width: 90%;
  }
}


img.img-small {
  max-height: 30%;
  max-width: 30%;
  @include until($tablet) {
    max-height: 100%;
    max-width: 100%;
  }
}


.no-overflow {
  overflow: unset !important;
}

.scai-feature {
  box-shadow: unset;
}

.scaidata-container-vertical {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h2 {
    padding-top: 2rem;
  }
}

div#bi_data_exploration, div#bi_share_dashboards {
  p {
   padding-top: 0.5rem;
  }
}

div#scaidata_sql_data_management_database_load_data {
  padding-top: 4rem;
}

div#scaidata_use_cases {
  div.item.columns {
    border-bottom: 1px solid $light;
  }
}

a.is-green, a.is-fuchsia {
  border-color: $white !important;
}

section#scai-cloud-platforms {
  background-color: $info;
  h2, p {
    color: $info-invert !important;
  }
  a.is-rounded {
    border-radius: 20px;
  }
}

.has-position-relative {
  position: relative;
}

@mixin set_background($color, $color-invert) {
  background-color: $color;
  color: $color-invert;
}

@each $name, $pair in $colors {
  $color: nth($pair, 1);
  $color-invert: nth($pair, 2);
  &.has-#{$name}-background {
    @include set_background($color, $color-invert);
  }
  @for $i from 1 to 20 {
    &.has-#{$name}-background-opacity-#{$i} {
      @include set_background(transparentize($color, 1 - $i / 20), $color-invert);
    }
  }
}

@for $i from 1 to 20 {
  .has-opacity-#{$i} {
    opacity: #{$i * 5 / 100};
  }
}

#whyscai {
  @include until(mobile) {
    div.column {
      text-align: center !important;
      padding-bottom: 1.5rem;
      i {
        padding-bottom: 0.7rem;
      }
    }
  }

  @include desktop {
    div.column {
      text-align: left !important;
      i {
        padding-bottom: 0.7rem;
      }
    }
  }
}

.img-overlay.small {
  @media screen and (min-width: 370px) {
    min-width: 330px;
  }
  @media screen and (max-width: 369px) {
    min-width: 250px;
  }
  max-width: 400px;
}

.img-overlay {
  position: relative;
  overflow: hidden;
  > .overlay-div, > .fixed-overlay-div {
    @include overlay();
  }

  &.opaque {
    > .overlay-div {
      opacity: 0;
      transition: opacity $default_transition;
    }
    @include onfocus {
      > .overlay-div {
        opacity: 0.9;
      }
    }
  }
  > .fixed-overlay-div {
    transition: opacity $default_transition;
  }

  @include onfocus {
    > .fixed-overlay-div {
      z-index: -1;
      opacity: 0;
    }
  }

  @each $dir in (left, right), (right, left), (bottom, top), (top, bottom) {
    $d: nth($dir, 1);
    $d-rev: nth($dir, 2);
    &.overlay-#{$d} {
      > .overlay-div {
        #{$d-rev}: 100%;
        #{$d}: -100%;
        transition: #{$d-rev} $default_transition, #{$d} $default_transition;
      }
      @include onfocus {
        > .overlay-div {
          #{$d-rev}: 0;
          #{$d}: 0;
        }
      }
    }
  }
}

#scai-difference {
  h2.title {
    margin-bottom: 0rem !important;
  }
  div.column.vertical-items {
    padding: 0.65rem !important;
    flex-direction: column;
    justify-content: center;
    display: unset;
  }
}

@for $i from 1 to 10 {
  .has-padding-left-#{$i} {
    padding-left: #{$i}rem;
  }
}

.is-full-height {
  height: 100%;
}

.slide-background {
  position: relative;
  overflow: hidden;
  .slide-div {
    position: absolute;
    top: -2000px;
    bottom: -2000px;
    left: -50%;
    right: 50%;
    transform: rotate(30deg);
    transform-origin: 100% 50%;
    z-index: -1;
    transition: .75s;
  }
  .slide-alt-div {
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;
    @include onfocus {
      & +.slide-div {
        left: 50%;
        right: -50%;
        transform: rotate(-150deg);
        @each $name, $pair in $colors {
          $color: nth($pair, 1);
          $color-invert: nth($pair, 2);
          &.has-#{$name}-background-alt {
            @include set_background($color, $color-invert);
          }
        }
      }
    }
  }
}

.has-no-zindex {
  z-index: auto;
}

@each $i in stretch, center, flex-start, flex-end, baseline, initial, inherit {
  .flex-align-#{$i} {
    align-items: #{$i};
  }
}

.flex-space-between {
  justify-content: space-between;
}

.transparent-button {
  padding: #{$transparent-button-radius / 4} #{$transparent-button-radius / 2};
  border-radius: $transparent-button-radius;
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      border: 1px solid $color;
      @include notonfocus {
        color: $color;
      }
    }
  }
  transition: all $default_transition;
  display: inline-block;
}

div.card-content {
  p.subtitle {
    font-size: 1.1rem !important;
  }
}

.is-hidden-until-widescreen {
  @include until($widescreen) {
    display: none;
  }
}

.small-logo {
  max-width: 100px;
}

.flex-none {
  flex: none;
}

@mixin expander {
  & >.expander {
    transition: transform 0.4s;
  }
  &:not(.open) > .expander {
    padding-top: 0.5em;
  }
  &.open > .expander {
    transform: rotate(-90deg);
  }
}

.hide-next {
  & + * {
    display: none;
  }
  @include expander;
  @include mobile {
    &-mobile {
      & + * {
        display: none;
      }
      @include expander;
    }
  }
}

.header-actions.with-image {
  @include mobile {
    display: flex;
    align-items: center;
    width: 100%;
    > * {
      flex: 0 1 auto;
    }
    > :not(:last-child) {
      margin-right: 1rem;
    }
    > .header-divider {
      flex: auto;
    }
    img {
      max-height: 6rem;
      width: unset;
    }
  }
}

.scai-keyword-underline {
  font-weight: 600;
  @include mobile {
    border-bottom: 2px solid $white;
    padding-bottom: 0.5rem;
    @include expander;
  }
}

.flex-important-mobile {
  @include mobile {
    display: flex !important;
  }
}

.with-white-borders {
  @include desktop {
    padding-left: 60px;
    border-left: 1px solid $white;
  }

  @include touch {
    margin-top: 2rem;
  }
}

@each $dir in left, right {
  .float-#{$dir} {
    float: $dir;
  }
}

.swiper-pagination {
  position: unset;
}

.swiper-pagination-bullet-active {
  background: $primary;
}

.swiper-button-next {
  right: -5px;
  outline: none;
}

div.scai-background {
  background: linear-gradient(-45deg, #66cdcc 0%, #336799 100%);
}

.scai-img-shadow {
  box-shadow: 0px 10px 10px $lighter-gray;
}

div.content > ul {
  margin-left: 1em !important;
}

.scai-list-reasons-use {
  p {
    padding-bottom: 1em;
  }
  span {
    color: $info;
  }
}

.scai-trial-form {
  padding: 2em;
  @include tablet {
    box-shadow: 0px 2px 20px rgba(64,129,166,0.8)
  }
}

.small-text {
  font-size: xx-small;
}

.is-hidden {
  display: none;
}

.scai-location {
  &:hover {
    color: $primary !important;
  }
  margin-left: 0.2rem;
}
