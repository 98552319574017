section#scaidata-platform-team {
  background: $info;
  h2, h3 {
    color: $white !important;
  }
}

.team-member-container {
    //Team member card
    .dark-card {
        border-radius: 8px;
        padding: 20px 0;
        background-color: $white;
        //Avatar wrapper
        .avatar {
            text-align: center;
            position: relative;
            height: 140px;
            padding: 10px 0;
            //Svg circle wrapping image
            .circle-chart {
                transform: scale(0.92);
                position: absolute;
                top: calc(50% - 70px);
                left: calc(50% - 70px);
            }
            //Member photo
            img {
                position: absolute;
                width: 100px;
                height: 100px;
                left: 0;
                right: 0;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                transition: all .5s;
            }
        }
        .avatar .circle-chart .circle-chart-circle{
          stroke-dasharray:  100, 100;
          stroke: $primary;
        }
        //Hover state
        &:hover {
            border: 4px solid $primary;
        }
        //Member meta
        .member-info {
            text-align: center;
            //position in company
            .position {
                text-transform: uppercase;
                font-size: 0.8rem;
                color: $info;
            }
            .email {
              font-size: 0.75rem;
              color: $info;
            }
            //Description
            .description {
                font-size: .8rem;
                padding: 10px 20px;
                color: $black;
            }
        }
    }
}
